import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Formik, useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuItem, TicketInfo } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import * as Yup from 'yup';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function TikcetInfoDialog(props: {
  tiketInfo?: {
    type: string;
    price: string;
    priceBreakup: string;
    summary: string;
    totalTickets: number;
    maxPerBooking: number;
    isActive: boolean;
  };
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  editIndex: number;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(props.editIndex !== undefined);
  const [fieldTicketInfo, metaTicketInfo, helpersTicketInfo] =
    useField('ticketsInfo');
  const [model, setModel] = useState<TicketInfo | null>(null);

  useEffect(() => {
    setOpen(props.isOpen);
    setModel(props.tiketInfo);
  }, [props.isOpen, props.tiketInfo]);

  const onSave = async (updatedModel: MenuItem) => {
    helpersTicketInfo.setValue([
      ...(fieldTicketInfo?.value || []),
      updatedModel
    ]);
    let isSuccess = true;
    try {
      setIsSaveInProgress(true);

      enqueueSnackbar(
        t(`Ticket info was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Ticket info`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {isEdit ? t(`Edit Ticket info`) : t('Add Ticket info')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update ticket info')
                : t('Fill in the fields below to create a new ticket info')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              type: model?.type || '',
              price: model?.price || '',
              priceBreakup: model?.priceBreakup || '',
              summary: model?.summary || '',
              totalTickets: model?.totalTickets || null,
              maxPerBooking: model?.maxPerBooking || null,
              isActive: isEdit
                ? model?.isActive === true
                  ? true
                  : false
                : true
            }}
            validationSchema={Yup.object().shape({
              type: Yup.string().required(t('Type is required')),
              price: Yup.string().required(t('Price is required')),

              summary: Yup.string().required(t('Summary is required')),
              totalTickets: Yup.number()
                .typeError(t('should be a number'))
                .required(t('Total Tickets is required')),

              maxPerBooking: Yup.number()
                .typeError(t('should be a number'))
                .max(
                  Yup.ref('totalTickets'),
                  t('Max per booking should be less than total tickets')
                )
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                const arr = [...fieldTicketInfo?.value];
                if (props.editIndex >= 0) {
                  arr[props.editIndex] = _values;
                  // arr.splice(props.editIndex, 1);
                } else {
                  arr.push(_values);
                }

                helpersTicketInfo.setValue(arr);
                var isSuccess = true;
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                props.onSave();
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.type && errors.type)}
                            fullWidth
                            helperText={touched.type && errors.type}
                            label={t('Ticket Type (category)')}
                            name="type"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.type}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.summary && errors.summary)}
                            fullWidth
                            helperText={touched.summary && errors.summary}
                            label={t('Summary')}
                            name="summary"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.summary}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.totalTickets && errors.totalTickets
                            )}
                            fullWidth
                            helperText={
                              touched.totalTickets && errors.totalTickets
                            }
                            label={t('Total Tickets')}
                            name="totalTickets"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.totalTickets}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.maxPerBooking && errors.maxPerBooking
                            )}
                            fullWidth
                            helperText={
                              touched.maxPerBooking && errors.maxPerBooking
                            }
                            label={t('Max Per Booking')}
                            name="maxPerBooking"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.maxPerBooking}
                            variant="outlined"
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.price && errors.price)}
                            fullWidth
                            helperText={touched.price && errors.price}
                            label={t('Price (per ticket)')}
                            name="price"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.price}
                            variant="outlined"
                            inputProps={{ inputMode: 'numeric' }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.priceBreakup && errors.priceBreakup
                            )}
                            fullWidth
                            helperText={
                              touched.priceBreakup && errors.priceBreakup
                            }
                            label={t('Price Breakup')}
                            name="priceBreakup"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.priceBreakup}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                name="isActive"
                                // disabled={!isEdit}
                                color={isEdit ? 'primary' : 'secondary'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.isActive}
                              />
                            }
                            label={t('Is Active')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default TikcetInfoDialog;
