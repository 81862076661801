import type { ReactNode } from 'react';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const menuItemsForAdmin: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Home',
        icon: StorefrontTwoToneIcon,
        link: '/app/home'
      }
    ]
  },

  {
    heading: 'BUSINESS',
    items: [
      {
        name: 'Events',
        icon: StorefrontTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/event/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/event/new'
          }
        ]
      },
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
          //{
          //  name: 'New',
          //  icon: AddTwoToneIcon,
          //  link: '/app/lead/new'
          //}
        ]
      }
    ]
  },
  {
    heading: 'ADMINISTRATION',
    items: [
      {
        name: 'Setting',
        icon: SettingsTwoToneIcon,
        link: '/app/setting'
      }
    ]
  }
];

export const menuItemsForNonAdminUser: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Main',
        icon: StorefrontTwoToneIcon,
        link: '/app/dashboard'
      }
    ]
  },
  {
    heading: 'MANAGEMENT',
    items: [
      {
        name: 'Company Profiles',
        icon: ListAltTwoToneIcon,
        link: '/app/cards/business'
      },
      {
        name: 'Member Profiles',
        icon: ListAltTwoToneIcon,
        link: '/app/cards/personal'
      },
      {
        name: 'My Cards',
        icon: CardMembershipTwoToneIcon,
        link: '/app/my-cards'
      }
    ]
  },
  {
    heading: 'BUSINESS',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
          // {
          //   name: 'Upload',
          //   icon: UploadTwoToneIcon,
          //   link: '/app/contact/upload'
          // }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
          //{
          //  name: 'New',
          //  icon: AddTwoToneIcon,
          //  link: '/app/lead/new'
          //}
        ]
      }
    ]
  }
];

export const menuItemsForIndividualUser: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Main',
        icon: StorefrontTwoToneIcon,
        link: '/app/dashboard'
      }
    ]
  },
  {
    heading: 'Profiles',
    items: [
      {
        name: 'Member Profiles',
        icon: PortraitTwoToneIcon,
        link: '/app/cards/personal'
      },
      {
        name: 'My Cards',
        icon: CardMembershipTwoToneIcon,
        link: '/app/my-cards'
      }
    ]
  },
  {
    heading: 'MANAGE',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          }
          // {
          //   name: 'Upload',
          //   icon: UploadTwoToneIcon,
          //   link: '/app/contact/upload'
          // }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Setting',
        icon: SettingsTwoToneIcon,
        link: '/app/setting'
      }
      //   {
      //     name: 'Reviews',
      //     icon: GroupAddTwoToneIcon,
      //     link: '',
      //     items: [
      //         {
      //             name: 'Reviews',
      //             icon: RateReviewIcon,
      //             link: '/app/review/list'
      //         },
      //         //{
      //         //  name: 'New',
      //         //  icon: AddTwoToneIcon,
      //         //  link: '/app/lead/new'
      //         //}
      //     ]
      // },
    ]
  }
];
