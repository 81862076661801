import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  appQueryClient,
  getEventById,
  getRefData,
  saveEvent
} from 'src/services/query-client';
import { EventDto, Events } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';

import { EVENT_TYPE } from 'src/services/ezzyEvents/constant';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import ContactInfo from './ContactInfo';
import EventDetails from './EventDetails';
import EventPhotos from './EventPhotos';
import Sidebar from './Sidebar';
import TicketInfo from './TicketInfo';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateEvent() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: eventId } = useParams();

  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(Boolean(eventId && !!eventId.length));

  const { user } = useSelector((state: RootState) => state.app);

  //Queries
  const {
    data: { data: accountRefData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery('ref-data', getRefData);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery(['event', eventId], (k) => getEventById(eventId), {
    enabled: isEdit
  });

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: EventDto) => saveEvent(e, eventId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  useEffect(() => {}, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: Events) => {
    enqueueSnackbar(
      t(`Event was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/event/edit/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(['event', eventId], updatedModel);
      refetchById();
    }
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/event/list`);
  };
  const sidebarContent = (
    <Scrollbar>
      <Sidebar isSubmit={isSubmit} />
    </Scrollbar>
  );

  if (isLoading || isRefDataLoading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...model,
          eventType: model?.type || '',
          eventTitle: model?.title || '',
          titleSummary: model?.titleSummary || '',
          eventDate: model?.eventDate,
          startTime: model?.startTime,
          endTime: model?.endTime,
          timeZone: model?.timeZone || '',
          desc: model?.desc || '',
          descRich: model?.descRich || '',
          venue: model?.venue,
          hostName: model?.hostName,
          contactEmail: model?.contactEmail || '',
          contactNumber: model?.contactNumber || '',
          website: model?.website || '',
          socialMediaUrls: {
            twitter: model?.socialMediaUrls?.twitter || '',
            instagram: model?.socialMediaUrls?.instagram || '',
            facebook: model?.socialMediaUrls?.facebook || '',
            externalUrl: model?.socialMediaUrls?.externalUrl || '',
            whatsApp: model?.socialMediaUrls?.whatsApp || ''
          },
          tags: model?.tags || [],
          displayImages: model?.displayImages || [],

          ticketsInfo: model?.tickets || [],

          attendeeLimit: model?.attendeeLimit || 0,

          status: model?.status || '',
          isActive: model?.isActive || false,

          organizerID: model?.organizerID || '',
          videoUrl: model?.videoUrl || '',
          faqs: model?.faqs || [],
          businessAccountId: model?.businessAccountId || ''
        }}
        validationSchema={Yup.object().shape({
          eventType: Yup.string().required(t('The field is required')),
          eventTitle: Yup.string().required(t('The field is required')),
          titleSummary: Yup.string().required(t('The field is required')),
          eventDate: Yup.date().required(t('The field is required')),
          startTime: Yup.date().required(t('The field is required')),
          endTime: Yup.date().required(t('The field is required')),
          timeZone: Yup.string().required(t('The field is required')),
          hostName: Yup.string().required(t('The field is required')),
          contactEmail: Yup.string()
            .email(t('Invalid email'))
            .required(t('The field is required')),
          contactNumber: Yup.string().required(t('The field is required')),
          website: Yup.string()
            .url(t('Invalid URL'))
            .required(t('The field is required')),
          venue: Yup.object().shape({
            location: Yup.string().required(t('The field is required')),
            venueName: Yup.string().required(t('The field is required')),
            address: Yup.string().required(t('The field is required')),
            gMap: Yup.object().required(t('The field is required'))
          }),
          displayImages: Yup.array()
            .of(
              Yup.object().shape({
                url: Yup.string().required(t('The field is required'))
              })
            )
            .min(1, t('At least one image is required')), // Ensure at least one item is present,
          ticketsInfo: Yup.array()
            .of(
              Yup.object().shape({
                type: Yup.string().required(t('The field is required')),
                price: Yup.number()
                  .typeError(t('should be a number'))
                  .required(t('The field is required')),
                totalTickets: Yup.number()
                  .typeError(t('should be a number'))
                  .required(t('The field is required'))
              })
            )
            .min(1, t('At least one ticket category is required'))
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          console.log('submitting', _values);
          const updatedModel: EventDto = {
            ..._values,
            type: _values.eventType as EVENT_TYPE,
            title: _values.eventTitle,
            tickets: _values.ticketsInfo,
            attendeeLimit: _values.ticketsInfo.reduce(
              (acc, curr) => acc + +curr.totalTickets,
              0
            )
          };

          setSubmitting(true);

          save(updatedModel);
          setStatus({ success: true });
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          isValid,
          errors,
          handleBlur,
          handleChange
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log('errors', errors)}
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        eventId={eventId}
                        onBack={handleBack}
                      />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <EventDetails accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <EventPhotos accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <TicketInfo accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        {/* <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                name="status"
                                // disabled={!isEdit}
                                color={isEdit ? 'primary' : 'secondary'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.status === 'DRAFT'}
                              />
                            }
                            label={t('Publish/Draft')}
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            component={RouterLink}
                            variant="outlined"
                            to={`/${
                              location.pathname.split('/')[1]
                            }/contact/list`}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{ ml: 2 }}
                            type="submit"
                            onClick={() => onSaveClick()}
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaving ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaving}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              {/* <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box> */}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateEvent;
