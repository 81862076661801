import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Field, FieldProps, useField } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import { EVENT_TYPE } from 'src/services/ezzyEvents/constant';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function EventDetails(props: { accountRefData: RefData }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const groupNames = props.accountRefData.contactGroups.map((o) => o.value);

  const [fieldEventTypes, metaEventType, helpersEventType] =
    useField('eventType');

  return (
    <Card>
      <CardHeader title={t('Event Details')} />
      <Divider />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
          <Grid item md={3} xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="address-state-select-filled-label">
                Event Types
              </InputLabel>
              <Select
                name="pronouns"
                labelId="pronouns-select"
                id="pronouns-select"
                value={fieldEventTypes.value}
                onChange={(_event) => {
                  helpersEventType.setValue(_event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Select...</em>
                </MenuItem>
                {props.accountRefData.eventTypes.map((o) => (
                  <MenuItem key={o.code} value={o.code as EVENT_TYPE}>
                    {o.value}
                  </MenuItem>
                ))}
              </Select>
              {metaEventType.touched && metaEventType.error && (
                <FormHelperText
                  error={
                    metaEventType.touched &&
                    (metaEventType.error ? true : false)
                  }
                >
                  {metaEventType.error}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={9} xs={12}>
            <Field
              fullWidth
              name="eventTitle"
              component={FmTextFiled}
              label="Title *"
              placeholder={t('event title here...')}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Field
              fullWidth
              name="titleSummary"
              component={FmTextFiled}
              label="Summary"
              placeholder={t('event summary here...')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Field name="eventDate">
              {({ field, form, meta }: FieldProps) => (
                <>
                  <DesktopDatePicker
                    label="Event date"
                    inputFormat="MM/dd/yyyy"
                    onChange={(newValue: Date | null) =>
                      form.setFieldValue(field.name, newValue)
                    }
                    value={field.value || null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText
                      error={meta.touched && (meta.error ? true : false)}
                    >
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
          </Grid>
          <Grid item md={3} xs={12}>
            <Field name="startTime">
              {({ field, form, meta }: FieldProps) => (
                <>
                  <DesktopTimePicker
                    label="Start time"
                    inputFormat="HH:mm"
                    onChange={(newValue: Date | null) =>
                      form.setFieldValue(field.name, newValue)
                    }
                    value={field.value || null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText
                      error={meta.touched && (meta.error ? true : false)}
                    >
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
          </Grid>
          <Grid item md={3} xs={12}>
            <Field name="endTime">
              {({ field, form, meta }: FieldProps) => (
                <>
                  <DesktopTimePicker
                    label="End time"
                    inputFormat="HH:mm"
                    onChange={(newValue: Date | null) =>
                      form.setFieldValue(field.name, newValue)
                    }
                    value={field.value || null}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText
                      error={meta.touched && (meta.error ? true : false)}
                    >
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={3}>
            <Field name="timeZone">
              {({ field, form, meta }: FieldProps) => (
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="timeZone">{t('Time Zone')}</InputLabel>
                  <Select
                    label={t('Time Zone')}
                    inputProps={{
                      name: 'timeZone'
                    }}
                    value={field.value}
                    onChange={(_event) => {
                      form.setFieldValue(field.name, _event.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>Select...</em>
                    </MenuItem>
                    {[
                      { code: 'us/est', value: 'Eastern Time' },
                      { code: 'us/cst', value: 'Central Time' }
                    ].map(({ value, code }) => (
                      <MenuItem key={code} value={code}>
                        {t(value)}
                      </MenuItem>
                    ))}
                  </Select>
                  {meta.touched && meta.error && (
                    <FormHelperText
                      error={meta.touched && (meta.error ? true : false)}
                    >
                      {meta.error}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="descRich">
              {({ field, form, meta }: FieldProps) => (
                <EditorWrapper>
                  <ReactQuill
                    placeholder="About Event"
                    value={field.value}
                    onChange={(content, delta, source, editor) => {
                      const text = editor.getText();
                      const textRich = editor.getHTML();
                      form.setFieldValue(field.name, textRich);
                      form.setFieldValue('desc', text);
                    }}
                  />
                </EditorWrapper>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="tags">
              {({
                field,
                form: {
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                  setFieldError
                },
                meta
              }: FieldProps) => (
                <Autocomplete
                  sx={{
                    m: 0
                  }}
                  freeSolo
                  multiple
                  limitTags={10}
                  value={field.value}
                  autoSelect
                  onChange={(event: any, newValues: string[] | null) => {
                    const vals = newValues.filter((o) => o.trim().length);
                    setFieldValue(field.name, vals);
                    setFieldTouched(field.name, true);
                  }}
                  options={groupNames}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder={t('Enter or select tags...')}
                    />
                  )}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Card>
  );
}

export default EventDetails;
