import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';

import {
  Alert,
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme
} from '@mui/material';

import { FieldArray, getIn, useField } from 'formik';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DeleteCardAction from 'src/components/DeleteCardAction';
import cdnService from 'src/services/cdnService';
import {
  CodeItem,
  RefData
} from 'src/services/ezzyEvents/apiService/responseModel';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(['all'])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const CardActions = styled(Box)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
  z-index: 7;
  display: flex;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);
const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

function EventPhotos(props: { accountRefData: RefData }) {
  const groupNames = [];
  // props.accountRefData.contactGroups.map((o) => o.value);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [fieldDisplayImages, metaDisplayImages, helpersDisplayImages] =
    useField('displayImages');

  const [isUploading, setIsUploading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const readAsDataURL = (inputFile: any): Promise<CodeItem> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve({
          code: temporaryFileReader.result?.toString() || '',
          value: inputFile.name
        });
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const onDropAccepted = async (files) => {
    const dataUrls: CodeItem[] = await Promise.all(
      files.map((file) => readAsDataURL(file))
    );

    if (isUploading) return;

    if (dataUrls.length) {
      setIsUploading(true);
      const fileNames: string[] = [];
      await Promise.all(
        dataUrls.map(async (dataUrl) => {
          const res = await fetch(dataUrl.code);
          const blob = await res.blob();
          const fileName = await cdnService.uploadFileToCloud(
            dataUrl.value,
            blob
          );
          fileNames.push(fileName);
        })
      );
      const fl = fileNames.map((fileName) => {
        return {
          url: fileName,
          title: '',
          desc: '',
          showAsMainPhoto: 'no'
        };
      });

      helpersDisplayImages.setValue([
        ...(fieldDisplayImages?.value || []),
        ...fl
      ]);

      setIsUploading(false);
    }
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 100,
    maxSize: 5242880,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'image/webp': ['.webp']
      //   'image/heic': ['.heic'],
      //   'image/heif': ['.heif']
    },
    onDropAccepted
  });

  return (
    <Card>
      <CardHeader
        avatar={
          <AvatarCloud variant="rounded">
            <ImageSearchRoundedIcon />
          </AvatarCloud>
        }
        title={t('Photos')}
        subheader={t('Share some stunning photographs showcasing your event.')}
      />
      <Divider />

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
        // sx={{ padding: theme.spacing(2) }} container spacing={1}
      >
        {
          <Grid item xs={12} md={12}>
            {metaDisplayImages.error && (
              <Box mt={1}>
                <Alert severity="error">{metaDisplayImages.error}</Alert>
              </Box>
            )}
            {!metaDisplayImages.error && (
              <Box mt={1}>
                <Alert severity="info">
                  <Box display="flex" alignItems="center">
                    <LabelWrapper>{t('Info')}</LabelWrapper>
                    <Box sx={{ pl: 2 }}>
                      {t(
                        'Upload high-quality images to attract more attendees to your event.'
                      )}
                    </Box>
                  </Box>
                </Alert>
              </Box>
            )}
            <Box
              mt={1}
              {...getRootProps()}
              component="div"
              className="feature-file-upload"
            >
              <input {...getInputProps()} />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or browse</p>
                  <AvatarCloud variant="rounded">
                    <CloudUploadTwoToneIcon />
                  </AvatarCloud>
                </div>
              </label>
            </Box>
            {isUploading && <LinearProgress />}
          </Grid>
        }
        <FieldArray name="displayImages">
          {(arrayHelpers) => {
            return (
              <Grid
                container
                direction="row"
                spacing={4}
                marginTop={4}
                marginLeft={1}
                marginBottom={4}
              >
                {arrayHelpers.form.values?.displayImages.length > 0 &&
                  arrayHelpers.form.values?.displayImages.map(
                    (displayImage, index) => (
                      <Grid key={index} item md={4} xs={12}>
                        <Card
                          sx={{
                            p: 2.5
                          }}
                          variant="outlined"
                        >
                          <Box>
                            <Box textAlign={'center'}>
                              <img
                                height={'150'}
                                src={cdnService.toImageUrl(displayImage.url)}
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Grid item xs={12} md={12} lg={12}>
                              <TextField
                                fullWidth
                                autoFocus={false}
                                id={'title'}
                                required={false}
                                name={`displayImages.${index}.title`}
                                value={displayImage.title}
                                label={'Title'}
                                onChange={arrayHelpers.form.handleChange}
                                error={
                                  getIn(
                                    arrayHelpers.form.touched,
                                    `displayImages.${index}.title`
                                  ) &&
                                  getIn(
                                    arrayHelpers.form.errors,
                                    `displayImages.${index}.title`
                                  )
                                    ? true
                                    : false
                                }
                                helperText={
                                  getIn(
                                    arrayHelpers.form.touched,
                                    `displayImages.${index}.title`
                                  ) &&
                                  getIn(
                                    arrayHelpers.form.errors,
                                    `displayImages.${index}.title`
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                              <TextField
                                fullWidth
                                autoFocus={false}
                                id={'subTitle'}
                                required={false}
                                name={`displayImages.${index}.desc`}
                                value={displayImage.desc}
                                label={'Subtitle'}
                                onChange={arrayHelpers.form.handleChange}
                                error={
                                  getIn(
                                    arrayHelpers.form.touched,
                                    `displayImages.${index}.desc`
                                  ) &&
                                  getIn(
                                    arrayHelpers.form.errors,
                                    `displayImages.${index}.desc`
                                  )
                                    ? true
                                    : false
                                }
                                helperText={
                                  getIn(
                                    arrayHelpers.form.touched,
                                    `displayImages.${index}.desc`
                                  ) &&
                                  getIn(
                                    arrayHelpers.form.errors,
                                    `displayImages.${index}.desc`
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                              <FormControl variant="filled" fullWidth>
                                <InputLabel id="address-state-select-filled-label">
                                  Set As
                                </InputLabel>
                                <Select
                                  name={`displayImages.${index}.showAsMainPhoto`}
                                  value={displayImage.showAsMainPhoto}
                                  labelId="pronouns-select"
                                  id="pronouns-select"
                                  onChange={(event) => {
                                    arrayHelpers.form.values?.services?.displayImages.map(
                                      (_, innerIndex) => {
                                        if (index !== innerIndex)
                                          arrayHelpers.form.setFieldValue(
                                            `displayImages.${innerIndex}.showAsMainPhoto`,
                                            'no'
                                          );
                                      }
                                    );
                                    arrayHelpers.form.handleChange(event);
                                  }}
                                >
                                  {[{ code: 'As Primary', value: 'yes' }].map(
                                    (o) => (
                                      <MenuItem key={o.code} value={o.value}>
                                        {o.code}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Box>

                          <DeleteCardAction
                            disabled={displayImage.showAsMainPhoto === 'yes'}
                            onDelete={() => arrayHelpers.remove(index)}
                          />
                        </Card>
                      </Grid>
                    )
                  )}
              </Grid>
            );
          }}
        </FieldArray>
      </Grid>
    </Card>
  );
}

export default EventPhotos;
