export enum BUSINESS_TYPE {
  EVENT_VENUE = 'event_venue',
  EVENT_VENDORS = 'event_vendors',
  EVENT_ORGANISER = 'event_organiser'
}

export enum BUSINESS_CATEGORIES {
  VENDOR_CATERERS = 'vendor_caterers',
  VENDOR_DECORATORS = 'vendor_decorators',
  VENDOR_PHOTOGRAPHY = 'vendor_photography',
  VENDOR_WEDDING_PLANNING = 'vendor_wedding_planning',
  VENDOR_VIDEOGRAPHY = 'vendor_videography',
  VENDOR_DJ = 'vendor_dj',
  VENDOR_HAIR_MAKEUP = 'vendor_hair_makeup',
  VENDOR_FLOWER = 'vendor_flower',

  //venue
  VENUE_BANQUET_HALLS = 'venue_banquet_halls',
  VENUE_HOTELS = 'venue_hotels'
}

export enum USER_TYPE {
  END_USER = 'end_user',
  BUSINESS_USER = 'business_user'
}

export enum EVENT_TYPE {
  OPEN_DOOR = 'OPEN DOOR',
  INAUGURATION = 'Inauguration',
  CONTEST = 'Contest',
  PARADE = 'Parade',
  CONCERT = 'Concert',
  COURSE = 'Course',
  EXPOSITION = 'Exposition',
  RAFFLE = 'Raffle'
}
