import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled
} from '@mui/material';
import {
  Field,
  Form,
  Formik,
  FormikConfig,
  FormikValues,
  useField
} from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import {
  Children,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import * as Yup from 'yup';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import oAuth from 'src/auth-service';
import { CountryCodeSlector } from 'src/components/country-code-selector';
import { AccountSignUp } from 'src/services/apiService/response-models';
import {
  businessUserSignup,
  checkBusinessAccountExistsByEmail,
  getBusinessCategories,
  getBusinessTypes
} from 'src/services/ezzyEvents/apiService';
import {
  BusinessCategoryResponse,
  BusinessType
} from 'src/services/ezzyEvents/apiService/responseModel';
import { getGoogleUser } from 'src/services/query-client';
import Logo from 'src/ui-bloom/components/LogoSign';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import parseReqType from 'src/utility/parseReqType';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import EmailVerifyStep from './email-verify-step';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);

const stripeAppearance = {
  theme: 'night',
  labels: 'floating'
};

const BoxActions = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]}
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      margin-left: auto;
      margin-right: auto;

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const dateRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;

const urlRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const sleep = (time: number) => new Promise((acc) => setTimeout(acc, time));
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
interface CountryCode {
  dial_code: string;
  name: string;
  code: string;
}

function SignUpWizard() {
  const { t }: { t: any } = useTranslation();
  const googleToken = oAuth.getSSOToken();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [openAlert, setOpenAlert] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ssoUser, setSsoUser] = useState(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [businessCategories, setBusinessCategories] = useState<
    BusinessCategoryResponse[]
  >([]);
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const requrl = searchParams.get('requrl');
  const reqtype = searchParams.get('reqtype');
  const reqType = parseReqType(reqtype);

  const loadCategoriesData = useCallback(
    async (
      callback: (hasError: boolean, data: BusinessCategoryResponse[]) => void
    ) => {
      if (isMountedRef.current) {
        const data = await getBusinessCategories();
        if (googleToken && searchParams.get('sso') == 'true') {
          const result = await getGoogleUser(googleToken);
          setSsoUser(result.data);
        }
        callback(false, data.data);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    setBusinessTypes(getBusinessTypes());
    loadCategoriesData((hasError, data) => {
      if (data) {
        setBusinessCategories(data);
      }
      setIsLoading(false);
    });
  }, [loadCategoriesData]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>Sign Up @ EzzyEvents</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth="md"
        >
          <Box sx={{}}>
            <Logo />
          </Box>
          <Card
            sx={{
              mt: 3,
              pt: 4
            }}
          >
            <Box px={4}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Sign up')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t('Fill in the fields below to sign up for an account.')}
              </Typography>
            </Box>

            <FormikStepper
              enableReinitialize={true}
              initialValues={{
                businessName: '',
                businessCategory: '',
                businessType: '',
                terms: true,
                location: '',
                password: '',
                password_confirm: '',
                email: ssoUser?.email || '',
                phone: '',
                countryCode: '1',
                isEmailVerified: false,
                verificationCode: ''
              }}
              onSubmit={async (_values) => {
                let isSuccess = false;
                setIsSaveInProgress(true);
                setIsSubmit(true);

                const model: AccountSignUp = {
                  email: _values.email,
                  password: _values.password,
                  phone: _values.phone,
                  businessName: _values.businessName,
                  location: _values.location,
                  businessCategory: _values.businessCategory,
                  businessType: _values.businessType,
                  terms: _values.terms
                };
                try {
                  await businessUserSignup(model);
                  enqueueSnackbar(t('Signup successfully'), {
                    variant: 'success'
                  });
                  isSuccess = true;
                } catch (ex) {
                  setIsSaveInProgress(false);
                  console.log(ex);
                  const msg = parseToApiErrorMessage(ex, 'Failed to signup');
                  enqueueSnackbar(t(msg), { variant: 'error' });
                }
                return isSuccess;
              }}
            >
              <FormikStep
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .required(t('The email field is required'))
                    .email(
                      t('The email provided should be a valid email address')
                    )
                    .max(255)
                    .test(
                      'Unique Email',
                      'Email already in use',
                      async (value) => {
                        if (ssoUser?.email) return true;
                        if (!value) return false;
                        if (
                          !value
                            .toLowerCase()
                            .match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            )
                        )
                          return false;
                        const result = await checkBusinessAccountExistsByEmail(
                          value
                        );
                        return !result.data;
                      }
                    ),
                  businessName: Yup.string()
                    .max(255)
                    .required(t('business name field is required')),
                  location: Yup.string()
                    .max(255)
                    .required(t('location field is required')),
                  businessType: Yup.string()
                    .max(255)
                    .required(t('business type field is required')),
                  businessCategory: Yup.string()
                    .max(255)
                    .required(t('business category field is required')),
                  password: Yup.string()
                    .min(8)
                    .max(255)
                    .required(t('The password field is required')),
                  password_confirm: Yup.string()
                    .oneOf(
                      [Yup.ref('password')],
                      t('Both password fields need to be the same')
                    )
                    .required(t('This field is required')),
                  phone: Yup.string()
                    .required(t('This field is required'))
                    .matches(phoneRegExp, 'Phone number is not valid'),
                  countryCode: Yup.string(),
                  terms: Yup.boolean().oneOf(
                    [true],
                    t('You must agree to our terms and conditions')
                  )
                })}
                label={t('Account Information')}
              >
                <Box p={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="businessName"
                        component={TextField}
                        label={t('Business Name')}
                        placeholder={t('Write your business name here...')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="email"
                        disabled={ssoUser?.email}
                        component={TextField}
                        label={t('Email')}
                        placeholder={t('Write your email here...')}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field name="businessType">
                        {({ field, form, meta }) => {
                          const isInvalid =
                            meta.touched && meta.error ? true : false;
                          return (
                            <FormControl error={isInvalid} fullWidth>
                              <InputLabel id="demo-select-small">
                                Business Type
                              </InputLabel>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={field?.value}
                                label="Business Type"
                                onChange={(e) =>
                                  form.setFieldValue(
                                    field.name,
                                    e.target.value || ''
                                  )
                                }
                              >
                                <MenuItem value={''}>Select</MenuItem>
                                {businessTypes.map((businessType) => (
                                  <MenuItem
                                    key={businessType.code}
                                    value={businessType.code}
                                  >
                                    {businessType.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                              {isInvalid && (
                                <FormHelperText error={isInvalid}>
                                  {meta.error}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="businessCategory">
                        {({ field, form, meta }) => {
                          const isInvalid =
                            meta.touched && meta.error ? true : false;
                          return (
                            <FormControl error={isInvalid} fullWidth>
                              <InputLabel id="demo-select-small">
                                Business Category
                              </InputLabel>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={field?.value}
                                label="Business Category"
                                onChange={(e) =>
                                  form.setFieldValue(
                                    field.name,
                                    e.target.value || ''
                                  )
                                }
                              >
                                <MenuItem value={''}>Select</MenuItem>
                                {businessCategories
                                  .filter(
                                    (b) =>
                                      form?.values?.businessType &&
                                      b.businessType ===
                                        form?.values?.businessType
                                  )
                                  .map((businessCategory) => (
                                    <MenuItem
                                      key={businessCategory.code}
                                      value={businessCategory.code}
                                    >
                                      {businessCategory.displayName}
                                    </MenuItem>
                                  ))}
                              </Select>
                              {isInvalid && (
                                <FormHelperText error={isInvalid}>
                                  {meta.error}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="location"
                        component={TextField}
                        label={t('City/Town')}
                        placeholder={t('Write your location here...')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        component={TextField}
                        label={t('Password')}
                        placeholder={t('Enter password here...')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        name="password_confirm"
                        component={TextField}
                        label={t('Confirm password')}
                        placeholder={t('Confirm password here...')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="countryCode">
                        {(props) => <CountryCodeSlector {...props} />}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="phone"
                        type="text"
                        component={TextField}
                        label={t('Phone number')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TermsField />
                    </Grid>
                  </Grid>
                </Box>
              </FormikStep>
              {!ssoUser?.email && (
                <FormikStep
                  validationSchema={Yup.object().shape({
                    verificationCode: Yup.string()
                      .max(6)
                      .required(t('The verification code field is required')),
                    isEmailVerified: Yup.boolean()
                      .oneOf([true], 'Please verify code first!')
                      .required(t('Please verify code first!'))
                  })}
                  label={t('Email Verification')}
                >
                  <EmailVerifyStep />
                </FormikStep>
              )}

              {
                <FormikStep label={t('Complete Registration')}>
                  <Box px={4} py={8}>
                    <Container maxWidth="sm">
                      <AvatarSuccess>
                        <CheckTwoToneIcon />
                      </AvatarSuccess>
                      <Collapse in={openAlert}>
                        <Alert
                          sx={{
                            mt: 3
                          }}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          severity="info"
                        >
                          {t(
                            'A welcome mail has been sent to your email address'
                          )}
                        </Alert>
                      </Collapse>
                      <Typography
                        align="center"
                        variant="h5"
                        color="text.primary"
                        fontWeight="bold"
                        sx={{
                          marginTop: 1,
                          mb: 0
                        }}
                      >
                        <Field name="email">
                          {({ field, meta }) =>
                            field.value ? field.value : ''
                          }
                        </Field>
                      </Typography>
                      <Typography
                        align="center"
                        variant="h4"
                        color="text.secondary"
                        fontWeight="normal"
                        sx={{
                          marginTop: 3,
                          mb: 3
                        }}
                      >
                        {t('Click below to sign in & start using your account')}
                      </Typography>

                      <Button fullWidth variant="contained" href={'/'}>
                        {'Continue to sign in'}
                      </Button>
                    </Container>
                  </Box>
                </FormikStep>
              }
            </FormikStepper>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = Children.toArray(
    children
  ) as ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const { t }: { t: any } = useTranslation();
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const retUrl = searchParams.get('returl');

  function isLastStep() {
    return step === childrenArray.length - (source?.length > 0 ? 1 : 2);
  }
  useEffect(() => {
    const scrollToTop = () => {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    };
    scrollToTop();
  }, [step]);

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          if (!source?.length) {
            const isSuccess = await props.onSubmit(values, helpers);
            if (isSuccess) {
              setCompleted(true);
              setStep((s) => s + 1);
            }
          } else {
            setCompleted(true);
            setStep((s) => s + 1);
          }
        } else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting, errors, touched, values }) => {
        return (
          <Form ref={ref} autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}
            {!completed ? (
              <BoxActions
                p={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {retUrl && step === 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => window.location.replace(retUrl)}
                  >
                    {t('Go back')}
                  </Button>
                ) : (
                  <Button
                    disabled={isSubmitting || step === 0}
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    {t('Previous')}
                  </Button>
                )}
                <Button
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={
                    (isLastStep() && source?.length > 0) || isSubmitting
                  }
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  {isSubmitting
                    ? t('Submitting')
                    : isLastStep()
                    ? source?.length > 0
                      ? t('Finish Payment')
                      : t('Complete Registration')
                    : t('Next step')}
                </Button>
              </BoxActions>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

function TermsField() {
  const [field, meta, helpers] = useField('terms');
  return (
    <>
      <Field
        name="terms"
        type="checkbox"
        component={CheckboxWithLabel}
        Label={{
          label: (
            <Typography variant="body2">
              {'I accept the'}{' '}
              <Link component="a" href="#">
                {'terms and conditions'}
              </Link>
              .
            </Typography>
          )
        }}
      />
      {Boolean(meta.touched && meta.error) && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </>
  );
}
export default SignUpWizard;
