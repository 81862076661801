import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { default as EditTwoToneIcon } from '@mui/icons-material/EditTwoTone';

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DeleteCardAction from 'src/components/DeleteCardAction';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import { TicketInfo as TICKET_INFO } from 'src/services/query-client/dto';
import TikcetInfoDialog from './TicketInfoDialog';

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);
const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

function TicketInfo(props: { accountRefData: RefData }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [fieldTicketsInfo, metaTicketsInfo, helpersTicketsInfo] =
    useField('ticketsInfo');
  const [fieldTicketsInfoItem, metaTicketsInfoItem, helpersTicketsInfoItem] =
    useField('ticketsInfoItem');

  return (
    <Card>
      <TikcetInfoDialog
        tiketInfo={fieldTicketsInfoItem.value}
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersTicketsInfoItem.setValue({});
        }}
        onSave={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersTicketsInfoItem.setValue({});
        }}
        editIndex={editIndex}
      />
      <CardHeader
        title={t('Ticket Categories')}
        subheader={t(
          'Add ticket categories to your event, each with its own price, quantity, and description.'
        )}
        action={
          <Button
            startIcon={<AddTwoToneIcon fontSize="small" />}
            onClick={() => {
              setOpen(true);
            }}
          >
            {'Add'}
          </Button>
        }
      />
      <Divider />
      {metaTicketsInfo.error && (
        <Box mt={1}>
          <Alert severity="error">{metaTicketsInfo.error}</Alert>
        </Box>
      )}
      {/* <Grid sx={{ padding: theme.spacing(2) }} container spacing={1}> */}
      <Box p={3}>
        <FieldArray name="ticketsInfo">
          {(arrayHelpers) => {
            return (
              <Grid
                container
                direction="row"
                spacing={2}
                marginTop={1}
                marginBottom={4}
              >
                <Typography variant="h6">
                  {t('Total Ticket Count: ')}
                  {arrayHelpers.form.values?.ticketsInfo.reduce(
                    (acc, curr) => acc + +curr.totalTickets,
                    0
                  )}
                </Typography>
                {arrayHelpers.form.values?.ticketsInfo.length == 0 && (
                  <Grid item xs={12} sm={12} md={2} lg={4}>
                    <Tooltip
                      arrow
                      title={t('Click to add a new ticket category')}
                    >
                      <CardAddAction
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <CardActionArea
                          sx={{
                            px: 1
                          }}
                        >
                          <CardContent>
                            <AvatarAddWrapper>
                              <AddTwoToneIcon fontSize="large" />
                            </AvatarAddWrapper>
                          </CardContent>
                        </CardActionArea>
                      </CardAddAction>
                    </Tooltip>
                  </Grid>
                )}
                {arrayHelpers.form.values?.ticketsInfo.length > 0 &&
                  arrayHelpers.form.values?.ticketsInfo.map(
                    (mn: TICKET_INFO, index) => (
                      <Grid key={mn.type} item md={4} xs={12}>
                        <CardWrapper>
                          <Box
                            sx={{
                              position: 'relative',
                              zIndex: '2'
                            }}
                          >
                            <Box
                              px={2}
                              pt={2}
                              display="flex"
                              justifyContent="space-between"
                            >
                              <IconButton
                                color="primary"
                                sx={{
                                  p: 0.5
                                }}
                                onClick={() => {
                                  helpersTicketsInfoItem.setValue(mn);
                                  setEditIndex(index);

                                  setOpen(true);
                                }}
                              >
                                <EditTwoToneIcon />
                              </IconButton>
                            </Box>
                            <Box p={2} display="flex" justifyContent={'center'}>
                              <Box>
                                <Box>
                                  <Typography variant="h4">
                                    Ticket type: <b>{mn.type}</b>
                                  </Typography>
                                  <Typography variant="h6">
                                    Total Tickets: {mn.totalTickets}
                                  </Typography>
                                  {mn.price && (
                                    <Typography
                                      sx={{
                                        pt: 1
                                      }}
                                      variant="h6"
                                    >
                                      Price per ticket: ${mn.price}
                                    </Typography>
                                  )}
                                  {mn.summary && (
                                    <Typography
                                      sx={{
                                        pt: 1
                                      }}
                                      variant="h6"
                                    >
                                      summary: {mn.summary}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Divider />
                            <DeleteCardAction
                              onDelete={() => {
                                const arr = [...fieldTicketsInfo.value];
                                arr.splice(index, 1);
                                helpersTicketsInfo.setValue(arr);
                              }}
                            />
                          </Box>
                        </CardWrapper>
                      </Grid>
                    )
                  )}
              </Grid>
            );
          }}
        </FieldArray>
      </Box>
      {/* </Grid> */}
    </Card>
  );
}

export default TicketInfo;
