import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  styled
} from '@mui/material';
import { Field, FieldProps, useField } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureImageField from 'src/components/FeatureImage/FeatureImageField';
import { MyMapComponent } from 'src/components/google-map';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);
const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

function ContactInfo(props: { isEdit: boolean }) {
  const { t }: { t: any } = useTranslation();
  const [
    fieldGoogleMapLocation,
    metaGoogleMapLocation,
    helpersGoogleMapLocation
  ] = useField('venue.gMap');
  const [fieldVenueName, metaVenueName, helpersVenueName] =
    useField('venue.venueName');
  const [fieldLocation, metaLocation, helpersLocation] =
    useField('venue.location');
  const [fieldAddress, metaAddress, helpersAddress] = useField('venue.address');

  const [currentTab, setCurrentTab] = useState<string>('contact');
  const [latLong, setLatLong] = useState(fieldGoogleMapLocation?.value?.latLng);
  const [openAuto, setOpenAuto] = React.useState(false);

  const tabs = [
    { value: 'contact', label: t('Contact') },
    { value: 'address', label: t('Address') },
    { value: 'social', label: t('Social networks') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };
  // useEffect(() => {
  //   setLatLong();
  // }, []);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE
    });

  return (
    <Card>
      <CardHeader
        avatar={
          <AvatarCloud variant="rounded">
            <ContactPageRoundedIcon />
          </AvatarCloud>
        }
        title={t('Contact Details')}
        subheader={t('contact details to be listed on event page')}
      />
      <Divider />
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Divider />
      <Box p={3}>
        {currentTab === 'contact' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="hostName"
                    variant="outlined"
                    label={t('Name')}
                    placeholder={t('Enter name here ...')}
                    component={FmTextFiled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="contactEmail"
                    variant="outlined"
                    label={t('Email')}
                    placeholder={t('Enter email here ...')}
                    component={FmTextFiled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="contactNumber"
                    variant="outlined"
                    label={t('Phone number (Work)')}
                    placeholder={t('Enter phone here ...')}
                    component={FmTextFiled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="website"
                    variant="outlined"
                    label={t('Website')}
                    placeholder={t('Enter website here ...')}
                    component={FmTextFiled}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FeatureImageField name="imageUrl" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {currentTab === 'address' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="venue.venueName"
                variant="outlined"
                label={t('Venue name')}
                placeholder={t('Enter venue here ...')}
              />
              {(metaVenueName.error && !metaVenueName.touched
                ? true
                : false) && (
                <FormHelperText error={metaVenueName.error ? true : false}>
                  {metaVenueName.error}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="venue.location"
                variant="outlined"
                label={t('City')}
                placeholder={t('Enter City here ...')}
              />
              {(metaLocation.error && !metaLocation.touched ? true : false) && (
                <FormHelperText error={metaLocation.error ? true : false}>
                  {metaLocation.error}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="venue.address"
                variant="outlined"
                label={t('Address line')}
                placeholder={t('Enter address here ...')}
              />
              {(metaAddress.error && !metaAddress.touched ? true : false) && (
                <FormHelperText error={metaAddress.error ? true : false}>
                  {metaAddress.error}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Field name="venue.gMap">
                {({
                  field,
                  form: {
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError
                  },
                  meta
                }: FieldProps) => (
                  <>
                    <Autocomplete
                      id="asynchronous-demo"
                      sx={{
                        m: 0
                      }}
                      open={openAuto}
                      value={field.value}
                      onChange={async (event: any, newValues: any | null) => {
                        setLatLong(null);
                        setFieldTouched(field.name, true);
                        const results = await geocodeByPlaceId(
                          newValues.place_id
                        );
                        const latLng = await getLatLng(results[0]);
                        setLatLong(latLng);
                        setFieldValue(field.name, {
                          ...newValues,
                          latLng
                        });
                      }}
                      onOpen={() => {
                        setOpenAuto(true);
                      }}
                      onClose={() => {
                        setOpenAuto(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      getOptionLabel={(option) =>
                        option?.description || 'Type Google address'
                      }
                      options={placePredictions}
                      loading={isPlacePredictionsLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('Google Map Address')}
                          onChange={(evt) =>
                            getPlacePredictions({
                              input: evt.target.value
                            })
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isPlacePredictionsLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    {(meta.error ? true : false) && (
                      <FormHelperText error={meta.error ? true : false}>
                        {meta.error}
                      </FormHelperText>
                    )}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {
                <MyMapComponent
                  value={latLong}
                  isMarkerShown
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `200px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              }
            </Grid>
          </Grid>
        )}
        {currentTab === 'social' && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="socialMediaUrls.instagram"
                variant="outlined"
                label={t('Instagram')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="socialMediaUrls.facebook"
                variant="outlined"
                label={t('Facebook')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="socialMediaUrls.externalUrl"
                variant="outlined"
                label={t('External URL')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PinterestIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="socialMediaUrls.twitter"
                variant="outlined"
                label={t('Twiter')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
}

export default ContactInfo;
