const ApiBaseUrl = process.env.REACT_APP_EZZY_EVENTS_API_NEST || '';
// const ApiBaseUrl = 'http://localhost:3005';
// const ezzyEventsApi = 'http://localhost:3005';
const ezzyEventsApi = process.env.REACT_APP_EZZY_EVENTS_API_NEST || '';

export const ApiUrls = {
  base: ezzyEventsApi,
  accountEndpoint: ApiBaseUrl + '/api/v1/account',
  manageCardEndpoint: ApiBaseUrl + '/api/v1/manage-card',
  viewerAppUrl: process.env.REACT_APP_VIEWERAPP_URL || '',
  clientEndpoint: ApiBaseUrl + '/api/v1/client-api',
  nfcKieazyUrl: 'https://kicard.co/kieazy',
  cardApi: process.env.REACT_APP_CARD_API_NEST || '',
  stripePaymentSuccessReturnUrl: process.env.REACT_APP_STRIPE_RETURN_URL || '',

  ezzyEventsApi,
  membershipEndpoint: ezzyEventsApi + '/api/v1/membership'
};

export const NEST_API_SECRET_KEY =
  process.env.REACT_APP_NEST_API_SECRET_KEY || '';
export const GOOGLE_OAUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';
