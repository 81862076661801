import axios, { AxiosResponse } from 'axios';
import { LogInRequest } from 'src/services/apiService/request-models';
import { LogInResult } from 'src/services/apiService/response-models';
import { ApiUrls } from 'src/services/endpoints';
import { BUSINESS_TYPE } from '../constant';
import { AccountSignUp } from './requestModel';
import {
  BusinessCategoryResponse,
  BusinessType,
  UserAccountEntity
} from './responseModel';

export const getBusinessCategories = (): Promise<
  AxiosResponse<BusinessCategoryResponse[]>
> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/categories';
  return axios.get(dataUrl);
};

export const getBusinessTypes = (): BusinessType[] => {
  return [
    {
      code: BUSINESS_TYPE.EVENT_VENUE,
      displayName: 'Venues and Banquets'
    },
    {
      code: BUSINESS_TYPE.EVENT_VENDORS,
      displayName: 'Vendors'
    },
    {
      code: BUSINESS_TYPE.EVENT_ORGANISER,
      displayName: 'Event Organiser'
    }
  ];
};

export const checkBusinessAccountExistsByEmail = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/business/exists/email/' + email;
  return axios.get(dataUrl);
};

export const sendEmailVerificationCode = (
  email: string
): Promise<AxiosResponse> => {
  const dataUrl =
    ApiUrls.ezzyEventsApi + '/account/sendVerificationCodeMail/' + email;
  return axios.get(dataUrl);
};

export const verifyEmail = (
  email: string,
  verificationCode: string
): Promise<
  AxiosResponse<{ isVerified: boolean; isVarificationCodeExpired: boolean }>
> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/account/verifyEmail';
  return axios.post(dataUrl, {
    email: email,
    verificationCode: verificationCode
  });
};

export const businessUserSignup = (
  req: AccountSignUp
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/account/signup/business-user';
  return axios.post(dataUrl, req);
};

export const getUserAccount = (): Promise<AxiosResponse<UserAccountEntity>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/account';
  return axios.get(dataUrl);
};

export const login = (
  userName: string,
  password: string
): Promise<AxiosResponse<LogInResult>> => {
  const data: LogInRequest = {
    userName: userName,
    password: password
  };
  const dataUrl = ApiUrls.membershipEndpoint + '/login';
  return axios.post(dataUrl, data);
};

export const logout = (token: string): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/logout';
  return axios.post(dataUrl, { token: token });
};

export const forgotPassword = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/forgot-password';
  return axios.post(dataUrl, { email: email });
};

export const checkUserExistsByEmail = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/exists/email/' + email;
  return axios.get(dataUrl);
};
