import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import { CssBaseline } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import oAuth from './auth-service';
import safeRouter from './router/safe-router';
import cdnService from './services/cdnService';
import { appQueryClient } from './services/query-client';
import { setUser as setUserInStore } from './store/slices/app';
import ThemeProvider from './ui-bloom/theme/ThemeProvider';
import readQueryString from './utility/readQueryString';

function App() {
  const content = useRoutes(router);
  const dispatch = useDispatch();
  const _safeRouter = useRoutes(safeRouter);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthInProgress, setIsAuthInProgress] = useState(true);
  const [isSafeRoute, setIsSafeRoute] = useState(false);
  const navigate = useNavigate();

  // const [brand, setBrand] = React.useState<Restaurant | undefined>();

  const SAFE_ROUTES = [
    '/signin-oidc',
    '/un-authorized',
    '/not-found',
    '/sign-up'
  ];

  function hideLoader(): void {
    document.getElementById('app-loader')?.remove();
  }

  function navigateToNotFound(): void {
    setIsSafeRoute(true);
    if (
      window.location.pathname.indexOf('/not-found') !== 0 &&
      window.location.pathname.indexOf('/un-authorized') !== 0
    )
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/not-found';
  }
  function navigateToUnAuth(): void {
    setIsSafeRoute(true);
    if (window.location.pathname.indexOf('/un-authorized') !== 0)
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.host +
        '/un-authorized';
  }

  React.useEffect(() => {
    axios.defaults.headers['clientApiKey'] = process.env.REACT_APP_CLIENTAPIKEY;
    axios.defaults.headers['clientApiSecrete'] =
      process.env.REACT_APP_CLIENTAPISECRET;

    if (
      SAFE_ROUTES.findIndex(
        (path) => window.location.pathname.indexOf(path) === 0
      ) != -1
    ) {
      setIsSafeRoute(true);
      hideLoader();
      return;
    }
    const tenantCode = 'ezzyevent-app';

    cdnService.init(tenantCode);

    console.log(process.env.REACT_APP_VER, 'tiket fix 2.0');

    oAuth
      .login()
      .then((o) => {
        dispatch(setUserInStore({ user: o }));
        setIsAuthenticated(true);
        setIsAuthInProgress(false);
        axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response?.status === 401) {
              sessionOut().then(() => {});
            }
            return error.response?.data
              ? Promise.reject(error.response.data.message)
              : Promise.reject(new Error(error));
          }
        );

        setTimeout(() => {
          if (localStorage.getItem('referer-path') == 'login') {
            // if (user.accountType == AccountTypes.Individual)
            //   navigate('/app/cards/personal');
            // else navigate('/app/cards/business');
          }
          hideLoader();
          localStorage.removeItem('referer-path');
        });

        // setInterval(() => {
        //   keepAlive()?.then(() => {});
        // }, 60000);
      })
      .catch(() => {
        const qs = readQueryString();
        if (qs.requrl) {
          localStorage.setItem('ezzy-event-login-requrl', qs.requrl);
        }
        if (qs.returl) {
          localStorage.setItem('ezzy-event-login-returl', qs.returl);
        }
        setIsAuthInProgress(false);
        hideLoader();
      });
  }, []);

  const sessionOut = async (): Promise<void> => {
    try {
      await oAuth.logout();
      oAuth.redirectToHome();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={appQueryClient}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <CssBaseline />
            {isAuthInProgress ? (
              <h3>Authenticating...</h3>
            ) : isAuthenticated ? (
              content
            ) : (
              _safeRouter
            )}
          </SnackbarProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
